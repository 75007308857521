<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :customFiltersShow="true"
          :zoneFilterShow="zone_agency_filter"
          :agencyFilterShow="zone_agency_filter"
          :locationFilterShow="true"
          :roleFilterShow="true"
          :processFilterShow="true"
          :workerFilterShow="true"
          :dateRangeFilterShow="true"
          :locationDefaultValue="true"
          :supervisorFilterShow="true"
          supervisorFilterPlaceholder="observer_filter"
          @changeFilter="updateFilter"
        )
        
  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        )

  div(v-else)
    b-row(v-if="!confirmations_total")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_confirmations_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-total(:statistic="confirmations_total")
        b-col(sm="3")
          statistic-card-vertical-index(:statistic="adherence_index")
      
      b-row
        b-col(cols="12")
          bar-chart-process(
            v-if="adherenceByProcess.labels && adherenceByProcess.labels.length",
            :process-data="adherenceByProcess"
          )
      
      b-row
        b-col(lg="6")
          bar-chart-worker-top(
            v-if="workerTopAdherence.labels && workerTopAdherence.labels.length",
            :worker-top-data="workerTopAdherence"
          )
        b-col(lg="6")
          bar-chart-worker-bottom(
            v-if="workerBottomAdherence.labels && workerBottomAdherence.labels.length",
            :worker-bottom-data="workerBottomAdherence"
          )

      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t('message.tableHeader.date') }}
              th {{ $t('message.tableHeader.worker') }}
              th {{ $t('message.tableHeader.observer') }}
              th {{ $t('message.tableHeader.location') }}
              th(v-if="commitmentFunctionality") {{ $t('message.tableHeader.zone') }}
              th {{ $t('message.tableHeader.process') }}
              th {{ $t('message.tableHeader.score') }}
              th {{ $t('message.tableHeader.total') }}
              th {{ $t('message.tableHeader.adherence') }}
              th {{ $t('message.tableHeader.action') }}
            b-tr(v-for="(confirmation, index) in confirmations", :key="index")
              b-td {{ confirmation.dateString }}
              b-td {{ confirmation.worker }}
              b-td {{ confirmation.observer }}
              b-td {{ confirmation.location }}
              b-td(v-if="commitmentFunctionality") {{ confirmation.zone }}
              b-td {{ confirmation.process }}
              b-td {{ confirmation.score }}
              b-td {{ confirmation.total }}
              b-td {{ confirmation.adherenceText() }}
              b-td
                view-button(@clicked="router.push({name: 'habit-confirmation-view', params: {id: confirmation.id}})")
                user-button(@clicked="router.push({name: 'apps-users-view', params: {id: confirmation.workerId}})")
      b-row
        b-col(md="6")
          b-button(:disabled="!confirmations.length" class="w-100")
            json-excel(:data="confirmations", :fields="excelFields")
              | {{ $t('download_xlsx') }}
        b-col(md="6" class="mt-md-auto mt-1")
          b-button(class="w-100" :disabled="!confirmations.length")
            json-excel(:data="confirmationsBehavior", :fields="excelFieldsBehavior")
              | {{ $t('download_xlsx_behavior') }}
</template>

<script>
import { onMounted, ref } from "@vue/composition-api/dist/vue-composition-api"
import { BTable, BTableSimple, BThead, BTr, BTd, BCard } from "bootstrap-vue"
import JsonExcel from "vue-json-excel";
import StatisticCardVerticalTotal from './charts/StatisticCardVerticalTotal.vue'
import StatisticCardVerticalIndex from './charts/StatisticCardVerticalIndex.vue'
import BarChartProcess from './charts/ChartjsBarChartProcess.vue'
import BarChartWorkerTop from './charts/ChartjsHorizontalBarChartWorkerTop.vue'
import BarChartWorkerBottom from './charts/ChartjsHorizontalBarChartWorkerBottom.vue'
import Filters from "@/views/organization/Filters.vue"
import i18n from '@/libs/i18n'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import UserButton from '@/views/components/Shared/Buttons/UserButton.vue'
import { useRouter } from '@core/utils/utils'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import store from "@/store"
import useCommon from '@/views/organization/useCommon'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    JsonExcel,
    StatisticCardVerticalTotal,
    StatisticCardVerticalIndex,
    Filters,
    ViewButton,
    UserButton,
    BarChartProcess,
    BarChartWorkerTop,
    BarChartWorkerBottom,
    Loading,
  },
  setup() {
    const { handleError } = useCommon()
    const { getItemsWithAggregate, ObjectId } = realmConnection()
    const userData = store.state?.userStore?.userData
    const clientId = userData.role !== "admin" ? userData.client.$oid : null
    const userLocations = userData.locations?.length ? userData.locations : null
    const confirmations = ref([])
    const confirmationsBehavior = ref([])
    const confirmations_total = ref(0)
    const adherence_index = ref(0);
    const adherenceByProcess = ref({})
    const workerTopAdherence = ref({})
    const workerBottomAdherence = ref({})
    const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : [])
    const roleFilter = ref([])
    const processFilter = ref([])
    const workerFilter = ref([])
    const supervisorFilter = ref([])
    const zoneFilter = ref([])
    const agencyFilter = ref([])
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const excelFields = ref({})
    const excelFieldsBehavior = ref({})
    const { router } = useRouter()
    const { processAdherence, getDatesFromRange } = useCommonDashboards()
    const isLoading = ref(true)
    const { zone_agency_filter, default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality

    async function listConfirmations() {
      isLoading.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      try {
        const initialQuery = {
          client_id: ObjectId(clientId),
          date: { $gte: startFilter, $lt: endFilter },
          pending: { $ne: true }
        }

        if (processFilter.value.length > 0) {
          initialQuery.process = { $in: processFilter.value.map(e => ObjectId(e)) }
        }

        if (workerFilter.value.length > 0) {
          initialQuery.worker = { $in: workerFilter.value.map(e => ObjectId(e)) }
        }

        if (supervisorFilter.value.length > 0) {
          initialQuery.supervisor = { $in: supervisorFilter.value.map(e => ObjectId(e)) }
        }

        const finalQuery = {}

        if (locationFilter.value.length > 0) {
          finalQuery['worker.locations._id'] = { $in: locationFilter.value }
        }

        if (zoneFilter.value.length > 0) {
          finalQuery['worker.locations.zone'] = { $in: zoneFilter.value }
        }

        if (agencyFilter.value.length > 0) {
          finalQuery['worker.locations.agency'] = { $in: agencyFilter.value }
        }

        if (roleFilter.value.length > 0) {
          finalQuery['worker.roles'] = { $in: roleFilter.value.map(e => ObjectId(e)) }
        }

        const pipeline = [
          { $match: initialQuery },
          {
            $lookup: {
              from: 'worker',
              localField: 'worker',
              foreignField: '_id',
              pipeline: [
                { $project: { name: 1, locations: 1, roles: 1 } },
                {
                  $lookup: {
                    from: 'location',
                    localField: 'locations',
                    foreignField: '_id',
                    pipeline: [ { $project: { location: 1, zone: 1, agency: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                    as: 'locations',
                  },
                },
                { $addFields: { _id: { $toString: "$_id" } } }
              ],
              as: 'worker'
            }
          },
          { $match: finalQuery },
          {
            $lookup: {
              from: 'worker',
              localField: 'supervisor',
              foreignField: '_id',
              pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'supervisor'
            }
          },
          {
            $lookup: {
              from: 'process',
              localField: 'process',
              foreignField: '_id',
              pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'process'
            }
          },
          { $addFields: { _id: { $toString: "$_id" }, worker: { $arrayElemAt: ["$worker", 0] }, supervisor: { $arrayElemAt: ["$supervisor", 0] }, process: { $arrayElemAt: ["$process", 0] } } },
        ]
      
        const items = await getItemsWithAggregate({ collection: 'confirmation', pipeline })

        parseConfirmationData(items, startFilter)
      } catch (error) {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_confirmation_list') })
      } finally {
        isLoading.value = false
      }
    }

    // Data for adherence XLSX File
    function parseConfirmationData(items, startFilter) {
      excelFields.value = {
        [i18n.t('message.tableHeader.date')]: "dateString",
        [i18n.t('message.tableHeader.worker')]: "worker",
        [i18n.t('message.tableHeader.observer')]: "observer",
        [i18n.t('message.tableHeader.location')]: "location",
        [i18n.t('message.tableHeader.zone')]: "zone",
        [i18n.t('message.tableHeader.process')]: "process",
        [i18n.t('message.tableHeader.scoreAptitude')]: "scoreAptitude",
        [i18n.t('message.tableHeader.scoreAttitude')]: "scoreAttitude",
        [i18n.t('message.tableHeader.score')]: "score",
        [i18n.t('message.tableHeader.totalAptitude')]: "totalAptitude",
        [i18n.t('message.tableHeader.totalAttitude')]: "totalAttitude",
        [i18n.t('message.tableHeader.total')]: "total",
        [i18n.t('message.tableHeader.adherenceAptitude')]: "adherenceAptitude",
        [i18n.t('message.tableHeader.adherenceAttitude')]: "adherenceAttitude",
        [i18n.t('message.tableHeader.adherence')]: "adherence",
        [i18n.t('message.tableHeader.duration')]: "duration",
        [i18n.t('message.tableHeader.comments')]: "comments",
      }

      excelFieldsBehavior.value = {
        [i18n.t('message.tableHeader.date')]: "dateString",
        ['ID']: 'id',
        [i18n.t('message.tableHeader.worker')]: "worker",
        [i18n.t('message.tableHeader.observer')]: "observer",
        [i18n.t('message.tableHeader.location')]: "location",
        [i18n.t('message.tableHeader.zone')]: "zone",
        [i18n.t('message.tableHeader.process')]: "process",
        [i18n.t('message.tableHeader.activity')]: "activity",
        [i18n.t('message.tableHeader.behaviour')]: "behaviour",
        [i18n.t('message.tableHeader.type')]: 'behaviourType',
        [i18n.t('message.tableHeader.scoreAptitudeDetail')]: "scoreAptitude",
        [i18n.t('message.tableHeader.scoreAttitudeDetail')]: "scoreAttitude",
      }

      if (commitmentFunctionality) {
        delete excelFields.value[i18n.t('message.tableHeader.scoreAptitude')]
        delete excelFields.value[i18n.t('message.tableHeader.scoreAttitude')]
        delete excelFields.value[i18n.t('message.tableHeader.totalAptitude')]
        delete excelFields.value[i18n.t('message.tableHeader.totalAttitude')]
        delete excelFields.value[i18n.t('message.tableHeader.adherenceAptitude')]
        delete excelFields.value[i18n.t('message.tableHeader.adherenceAttitude')]
        
        delete excelFieldsBehavior.value[i18n.t('message.tableHeader.type')]
        delete excelFieldsBehavior.value[i18n.t('message.tableHeader.scoreAptitudeDetail')]
        delete excelFieldsBehavior.value[i18n.t('message.tableHeader.scoreAttitudeDetail')]
        excelFieldsBehavior.value[i18n.t('message.tableHeader.score')] = "score"
      } else {
        delete excelFields.value[i18n.t('message.tableHeader.zone')]
        delete excelFieldsBehavior.value[i18n.t('message.tableHeader.zone')]
      }

      // Change the dates so that it matches the UTC time zone
      const timezoneOffsetHours = startFilter.getTimezoneOffset() / 60

      // Excel with total data
      let confirmationData = []
      let adherenceData = {score: 0, total: 0}
      let processData = {}
      let workerData = {}

      // Excel with broken-down data
      let confirmationBehaviourData = []

      for (const confirmation of items) {
        if (confirmation.total) {
          const date = confirmation.date
          date.setHours(date.getHours() + timezoneOffsetHours)
          const processName = confirmation.process?.name
          const processId = confirmation.process?._id
          const workerName = confirmation.worker?.name
          const workerId = confirmation.worker?._id
          const score = confirmation.score
          const total = confirmation.total
          const scoreAttitude = confirmation.scoreAttitude || 0
          const totalAttitude = confirmation.totalAttitude || 0
          const scoreAptitude = confirmation.scoreAptitude || 0
          const totalAptitude = confirmation.totalAptitude || 0
          const adherenceAttitude = totalAttitude === 0 ? "-" : processAdherence(scoreAttitude, totalAttitude)
          const adherenceAptitude = totalAptitude === 0 ? "-" : processAdherence(scoreAptitude, totalAptitude)

          let comments = ""
          if(confirmation?.activities[confirmation.activities?.length - 1]){
            for (let comentario in confirmation.activities[confirmation.activities.length - 1]?.behaviours){
              if (confirmation.activities[confirmation.activities.length - 1].behaviours[comentario].type === "text"){
                comments = confirmation.activities[confirmation.activities.length - 1].behaviours[comentario].answer
              }
            }
          }

          // Excel with total data
          let payload = {
            date,
            dateString: date.toLocaleDateString(`${default_language || 'en'}-US`),
            worker: workerName,
            observer: confirmation.supervisor?.name,
            location: confirmation.worker?.locations?.map(e => e.location).join(" / "),
            process: processName,
            score: score,
            total: total,
            adherence: processAdherence(score, total),
            adherenceText: function() {
              if (this.total === 0) return "-"
              return `${this.adherence}%`
            },
            adherenceAttitude: adherenceAttitude, // It only shows in Excel
            adherenceAptitude: adherenceAptitude, // It only shows in Excel
            duration: confirmation.duration,
            id: confirmation._id,
            workerId: workerId,
            comments: comments,
            scoreAttitude: scoreAttitude, // It only shows in Excel
            totalAttitude: totalAttitude, // It only shows in Excel
            scoreAptitude: scoreAptitude, // It only shows in Excel
            totalAptitude: totalAptitude, // It only shows in Excel
          }

          // Add metadata fields if applicable
          if (confirmation.metadata) {
            confirmation.metadata.forEach(e => {
              payload[e.name] = e.answer
              if (!excelFields.value.hasOwnProperty(i18n.t(`metadata.${e.name}`))) {
                excelFields.value[i18n.t(`metadata.${e.name}`)] = e.name
              }
            })
          }
          
          // Add the most repeated area
          let uniqueZone = ''
          const zone = confirmation.worker?.locations?.map(l => l.zone)

          if (zone && zone.length > 0) {
            // Count the quantity of each zone
            const zoneCounts = zone.reduce((counts, z) => {
              counts[z] = (counts[z] || 0) + 1;
              return counts;
            }, {})

            // Find the zone that is most repeated
            let mostFrequentZone = zone[0];
            let maxCount = 1
            
            // mostFrequentZone and maxCount only if we find a zone with more repetitions than the current one.
            for (const z of zone) {
              if (zoneCounts[z] > maxCount) {
                mostFrequentZone = z;
                maxCount = zoneCounts[z]
              }
            }
            uniqueZone = mostFrequentZone
            
          } else {
            uniqueZone = ''
          }
          payload.zone = uniqueZone
          confirmationData.push(payload)
          adherenceData.score += score
          adherenceData.total += total

          // Data for bar charts
          if (processName) updateObjectData(processData, processName, processId, score, total)
          if (workerName) updateObjectData(workerData, workerName, workerId, score, total)

          // Excel with broken-down data
          for (const activity of confirmation.activities) {
            const activityName = activity.name
            for (const behaviour of activity.behaviours) {
              
              const behaviourName = behaviour.name || ''
              const behaviourType = 
                behaviour.type === 'rating' ? i18n.t('message.tableHeader.attitude')
                : behaviour.type === 'bool' ? i18n.t('message.tableHeader.aptitude')
                : i18n.t('message.tableHeader.other')
              let scoreAptitude
              let scoreAttitude
              let score

              if (behaviour.answer === 'unknown') {
                scoreAptitude = scoreAttitude = 'N/A'
              } else {
                if (behaviour.type === 'statement' || behaviour.type === 'text') {
                  scoreAptitude = scoreAttitude = '-'
                }
                if (behaviour.type === 'rating') {
                  scoreAttitude = (behaviour.answer / 5) || 'N/A'
                  scoreAptitude = '-'
                }
                if (behaviour.type === 'bool') {
                  const answer = behaviour.answer ? behaviour.answer.trim() : 'N/A'
                  if (answer === 'true') {
                    scoreAptitude = 1
                    scoreAttitude = '-'
                  } else if (answer === 'false') {
                    scoreAptitude = 0
                    scoreAttitude = '-'
                  } else {
                    scoreAptitude = scoreAttitude = 'N/A'
                  }
                }
              }

              // Determine totalScore
              const aptitudeScore = !isNaN(scoreAptitude) ? parseFloat(scoreAptitude) : null
              const attitudeScore = !isNaN(scoreAttitude) ? parseFloat(scoreAttitude) : null

              if (aptitudeScore !== null && attitudeScore !== null) {
                score = aptitudeScore + attitudeScore
              } else if (aptitudeScore !== null) {
                score = aptitudeScore
              } else if (attitudeScore !== null) {
                score = attitudeScore
              } else {
                score = '-'
              }

              const payload = {
                date,
                dateString: date.toLocaleDateString(`${default_language || 'en'}-US`),
                worker: workerName,
                observer: confirmation.supervisor?.name,
                location: confirmation.worker?.locations?.map(e => e.location).join(" / "),
                zone: uniqueZone,
                process: processName,
                activity: activityName,
                behaviour: behaviourName,
                behaviourType,
                scoreAptitude,
                scoreAttitude,
                behaviourData: behaviour,
                score,
                id: confirmation._id
              }
              confirmationBehaviourData.push(payload)
            }
          }
        }
      }
      confirmationsBehavior.value = confirmationBehaviourData
      
      // Excel with total data
      confirmationData.sort(function (a, b) {
        if (b.score === 0 && a.score === 0) return b.total - a.total
        return a.adherence - b.adherence
      })
      confirmations.value = confirmationData
      confirmations_total.value = confirmationData.length
      excelFields.value[i18n.t('message.tableHeader.id')] = "id"
      adherence_index.value = adherenceData.total === 0
        ? "-"
        : `${processAdherence(adherenceData.score, adherenceData.total)}%`

      // Set charts data
      adherenceByProcess.value = setChartsData(processData, "desc")
      workerTopAdherence.value = setChartsData(workerData, "desc", 10)
      workerBottomAdherence.value = setChartsData(workerData, "asc", 10)
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      processFilter.value = data.processFilter;
      workerFilter.value = data.workerFilter;
      supervisorFilter.value = data.supervisorFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      listConfirmations();
    }

    const updateObjectData = (object, property, propertyId, score, total) => {
      if (object.hasOwnProperty(property)) {
        object[property].score += score
        object[property].total += total
        object[property].confirmations += 1
      }
      else object[property] = {
        score: score,
        total: total,
        confirmations: 1,
        id: propertyId
      }
    }

    const setChartsData = (objectData, sortDirection, dataLimit) => {
      for (const property in objectData) {
        objectData[property].adherence = processAdherence(objectData[property].score, objectData[property].total)
      }

      let dataSorted = Object.entries(objectData)

      dataSorted.sort(function (a, b) {
        if (b[1].score === 0 && a[1].score === 0) return sortDirection === "desc" ? a[1].total - b[1].total : b[1].total - a[1].total
        return sortDirection === "desc" ? b[1].adherence - a[1].adherence : a[1].adherence - b[1].adherence
      })

      const limit = dataLimit < dataSorted.length ? dataLimit : dataSorted.length

      let labels = []
      let adherenceValues = []
      let confirmationValues = []
      let idValues = []

      for (let i = 0; i < limit; i++) {
        labels.push(dataSorted[i][0])
        adherenceValues.push(dataSorted[i][1].adherence)
        confirmationValues.push(dataSorted[i][1].confirmations)
        idValues.push(dataSorted[i][1].id)
      }

      return {
        labels: labels,
        data: {
          adherence: adherenceValues,
          confirmations: confirmationValues
        },
        additionalInfo: {
          ids: idValues,
        }
      }
    }

    onMounted(() => {
      listConfirmations()
    })

    return {
      updateFilter,
      confirmations,
      confirmations_total,
      confirmationsBehavior,
      adherence_index,
      adherenceByProcess,
      workerTopAdherence,
      workerBottomAdherence,
      excelFields,
      excelFieldsBehavior,
      router,
      isLoading,
      zone_agency_filter,
      colors,
      commitmentFunctionality
    };
  },
};
</script>
