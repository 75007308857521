<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <!-- <b-card-sub-title class="mb-25">
          Balance
        </b-card-sub-title> -->
        <b-card-title>{{ $t('topTenAverageAdherenceByWorker') }}</b-card-title>
      </div>
      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-horizontal-bar-chart
        :height="400"
        :data="this.series"
        :options="horizontalBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentHorizontalBarChart from '@/views/habit/charts-components/ChartjsComponentHorizontalBarChart.vue'
import { $themeColors } from '@themeConfig'
import i18n from '@/libs/i18n'
import { EventBus } from "@/views/habit/EventBus.js";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    ChartjsComponentHorizontalBarChart,
  },
  props: {
    workerTopData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      horizontalBarChart: {
        options: {
          onClick: function (c, i) {
            let e = i[0];
            if (!e) return
            // var x_value = this.data.labels[e._index];
            // var y_value = this.data.datasets[0].data[e._index];
            const workerId = this.data._additionalInfo.workerIds[e._index]
            EventBus.$emit("filterByWorker", workerId);
          },
          hover: {
            onHover: function(e) {
              var point = this.getElementAtEvent(e)
              if (point.length) e.target.style.cursor = 'pointer';
              else e.target.style.cursor = 'default';
            }
          },
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label: function(tooltipItem, data) {
                return `${i18n.t('label.adherence')}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}%`
              },
              afterBody: function(tooltipItem, data) {
                return `${i18n.t('label.confirmations')}: ${data._additionalInfo.confirmations[tooltipItem[0].index]}`
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                  borderColor: 'transparent',
                  color: 'rgba(200, 200, 200, 0.2)',
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10,
                  fontColor: '#6e6b7b',
                  precision: 0,
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
          },
        },
        // data: {
        //   labels: ['MON', 'TUE', 'WED ', 'THU', 'FRI', 'SAT', 'SUN'],
        //   datasets: [
        //     {
        //       data: [710, 350, 470, 580, 230, 460, 120],
        //       backgroundColor: $themeColors.info,
        //       borderColor: 'transparent',
        //       barThickness: 15,
        //     },
        //   ],
        // },
      },
      // rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
  computed: {
    series() {
      return {
        labels: this.workerTopData.labels,
        datasets: [
          {
            data: this.workerTopData.data.adherence,
            backgroundColor: $themeColors.info,
            borderColor: 'transparent',
            barThickness: 15,
          },
        ],
        _additionalInfo: {
          confirmations: this.workerTopData.data.confirmations,
          workerIds: this.workerTopData.additionalInfo.ids
        }
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
